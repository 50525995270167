<template>
    
    <div>
        <myHead/>
        <div style="padding:20px">
            <a-row  style="width:100%; ">
                <a-col :span="24" style="margin-left:20px"  >
                    <a-card style="">
                        <h3> <BarChartOutlined /> 收入概览</h3>
                         <a-divider  />
                             <div id="myChart" :style="'width: '+dataWidth+'px; height: 156px;'">
                            </div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row  style="width:100%;margin-top:20px">
                <a-col :span="24" style="margin-left:20px"  >
                    <a-card style="">
                        <h3> <UsergroupAddOutlined /> 推广数据</h3>
                         <a-divider  />
                        <a-table 
                        :rowKey="record => record.Id" 
                        :columns="columns"  
                        :loading="isLoading"
                        :data-source="memberList"
                        :pagination="false">
                            <template #balanceText="{ text }">
                                <span style="font-weight:500">
                                    {{text}}
                                </span>
                            </template>
                                <template #payoutText="{ text }">
                                <span style="font-weight:500">
                                    {{text}}
                                </span>
                            </template>

                            <template #status="{ text }">
                                    <a-tag v-if="text==1" color="#67C23A">正常</a-tag>
                                    <a-tag v-else color="#F56C6C">停用</a-tag>
                            </template>
                            <template #shopNum="{ text }">
                                    <a-tag color="red">{{text}}</a-tag>
                            </template>
                            <template #lastTaskDate="{ text }">
                                    {{text?text.split(' ')[0]:'一个月之前'}}
                            </template>
                            <template #lostDays="{ text }">
                                    <a-tag v-if="text<=1" color="#67C23A">{{text}}</a-tag>
                                    <a-tag v-else-if="text<7" color="#E6A23C">{{text}}</a-tag>
                                    <a-tag v-else-if="text<35" color="#F56C6C">{{text}}</a-tag>
                                    <a-tag v-else color="#909399">>35</a-tag>
                            </template>
                    </a-table>


                    </a-card>
                </a-col>
            </a-row>
        </div>
         <myFooter/>
    </div>
</template>
<script>
import myHead from '../components/myHead'
import myFooter from '../components/myFooter'

import {promoteGetIncome,promoteGetMember} from '@/request.js'


import {UsergroupAddOutlined,BarChartOutlined} from '@ant-design/icons-vue';

export default {
    components:{ myHead,myFooter,BarChartOutlined,UsergroupAddOutlined},
    data(){
        return{
            dataWidth:0,
                queryModel:{
                phone:"",
                createTimes:undefined,
                promoterId:undefined,
            },
            memberList:[],
            isLoading:false,

            columns:[
                {
                    title: 'Id',
                    dataIndex: 'Id',
                    key: 'Id',
                },
                {
                    title: '手机号',
                    dataIndex: 'Phone',
                    key: 'Phone',
                },
                {
                    title: '现金账户',
                    dataIndex: 'BalanceText',
                    key: 'BalanceText',
                    slots: { customRender: 'balanceText' },
                },
                {
                    title: '赔付账户',
                    dataIndex: 'PayoutText',
                    key: 'PayoutText',
                    slots: { customRender: 'payoutText'},
                },
                {
                    title: '注册时间',
                    dataIndex: 'CreateTime',
                    key: 'CreateTime',
                    slots: { customRender: 'createTime' },
                },

                {
                    title: '状态',
                    dataIndex: 'Status',
                    key: 'Status',
                    slots: { customRender: 'status' },
                },
                {
                    title: '店铺数量',
                    dataIndex: 'ShopNum',
                    key: 'ShopNum',
                    slots: { customRender: 'shopNum' },
                },
                {
                    title: '最后放单',
                    dataIndex: 'LastTaskDate',
                    key: 'LastTaskDate',
                    slots: { customRender: 'lastTaskDate' },
                },
                {
                    title: '流失天数',
                    dataIndex: 'LostDays',
                    key: 'LostDays',
                    slots: { customRender: 'lostDays' },
                },
            ],
        }
    },
    created(){
        this.dataWidth=document.body.clientWidth-120;
    },
    mounted(){
        if(this.$route.query.topUpVisible){
            this.topUpVisible=true;
        }
        let myChart = this.$root.echarts.init(
            document.getElementById("myChart")
        );
        promoteGetIncome({},(res)=>{
             myChart.setOption(res.Result);
        });
        promoteGetMember({
            createTimes:"[]",
            phone:"",
        },(res)=>{
            this.memberList=res.Result;
        })
    },
    methods:{
       
    }
}
</script>
<style scoped>

</style>